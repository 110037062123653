// extracted by mini-css-extract-plugin
export var h1Typography = "authentication-module--h1-typography--g9SVw";
export var h2Typography = "authentication-module--h2-typography--JFnmp";
export var h2TypographySecond = "authentication-module--h2-typography-second--AvvUc";
export var h3Typography = "authentication-module--h3-typography--kPJkT";
export var h3TypographyError = "authentication-module--h3-typography-error--3D+-0";
export var h4Typography = "authentication-module--h4-typography--CPfO2";
export var pTypography = "authentication-module--p-typography--DKXxy";
export var smallPTypography = "authentication-module--small-p-typography--lmEWV";
export var loginNavigateLink = "authentication-module--login-navigate-link--2TP9b";
export var sidebarTypography = "authentication-module--sidebar-typography--HdGpC";
export var errorTypography = "authentication-module--error-typography--nTzOb";
export var loginError = "authentication-module--login-error--sFmlS";
export var captionTypography = "authentication-module--caption-typography--+5+IE";
export var authMessageLabelTypography = "authentication-module--auth-message-label-typography--TE+Sm";
export var authMessageTypography = "authentication-module--auth-message-typography--Jns2f";
export var versionInfoTypography = "authentication-module--version-info-typography--fpzUi";
export var itemHidden = "authentication-module--item-hidden--GeJnk";
export var ___gatsby = "authentication-module--___gatsby--OoP68";
export var gatsbyFocusWrapper = "authentication-module--gatsby-focus-wrapper--m51Cl";
export var loginForm = "authentication-module--login-form--+KW7J";
export var loginFormSuccess = "authentication-module--login-form-success--GvP1B";
export var boldedText = "authentication-module--bolded-text--6mJ2F";
export var loginMessagesContainer = "authentication-module--login-messages-container--tV+j+";
export var resetPassword = "authentication-module--reset-password--Dw5Va";
export var formMessageContainer = "authentication-module--form-message-container--6NhMd";
export var formSuccessMessageContainer = "authentication-module--form-success-message-container--8IdFM";
export var formButtonsContainer = "authentication-module--form-buttons-container--o8hXs";
export var header = "authentication-module--header--WoDlF";
export var capslockContainer = "authentication-module--capslock-container--Q2jWP";
export var validatorContainer = "authentication-module--validator-container--NKRS-";
export var validatorText = "authentication-module--validator-text--NGklG";
export var gap = "authentication-module--gap--sLB6w";