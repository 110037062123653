import {
  fontFamilyName,
  inputBorder,
  inputBorderFocused,
  inputBorderHovered,
  tertiaryColor,
} from "../../styles/mui/theme";

export const commonFormBase = {
  input: {
    "width": "315px",
    "height": "56px",
    "fontFamily": fontFamilyName,
    "& label": {
      "fontFamily": fontFamilyName,
      "color": inputBorder,
      "&.Mui-focused": {
        color: inputBorderFocused,
      },
    },
    "&.MuiFormHelperText-root, &.Mui-error, &.MuiFormHelperText-sizeMedium, &.MuiFormHelperText-contained, &.MuiFormHelperText-filled, &.css-1wc848c-MuiFormHelperText-root": {
      fontFamily: fontFamilyName,
    },
    "& .MuiOutlinedInput-root": {
      "&.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root": {
        fontFamily: fontFamilyName,
      },
      "&.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root": {
        fontFamily: fontFamilyName,
      },
      "&.Mui-focused fieldset": {
        borderColor: inputBorderFocused,
        fontFamily: fontFamilyName,
      },
      "& fieldset": {
        borderColor: inputBorder,
        borderWidth: "2px",
        fontFamily: fontFamilyName,
      },
      "&:focus fieldset": {
        borderColor: inputBorderFocused,
        fontFamily: fontFamilyName,
      },
      "&:hover fieldset": {
        borderColor: inputBorderHovered,
        fontFamily: fontFamilyName,
      },
    },
  },
  passwordVisibility: {
    fontFamily: fontFamilyName,
    color: tertiaryColor,
  },
};

export const muiStylesAuthentication = {
  inputEmail: {
    ...commonFormBase.input,
    marginBottom: "30px",
    lowercase: { textTransform: "lowercase" },
    fontFamily: fontFamilyName,
  },
  inputPassword: {
    ...commonFormBase.input,
    fontFamily: fontFamilyName,
  },
  inputResetPassword: {
    ...commonFormBase.input,
    width: "350px",
    fontFamily: fontFamilyName,
  },
};
