import * as yup from "yup";
import { I18n } from "react-redux-i18n";

export const passwordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .min(8, "Your password must be at least 8 characters long.")
    .matches(/[0-9]/, "Your password must contain at least 1 digit (0-9)")
    .matches(/[A-Z]/, "Your password must contain at least 1 uppercase letter")
    .matches(/[a-z]/, "Your password must contain at least 1 lowercase letter")
    .matches(
      /[()[\]{}|\\`~!@#$%^&*_\-+=;:'",<>./?]/,
      "Your password must contain at least 1 special character"
    ),
});

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Entered value does not match email format")
    .required(I18n.t("FormValidationErrors.required")),
  password: yup.string().required(I18n.t("FormValidationErrors.required")),
});

export const getResetPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email(I18n.t("FormValidationErrors.email"))
    .required(I18n.t("FormValidationErrors.required")),
});

export const pinSchema = yup.object().shape({
  pin: yup
    .string()
    .required()
    .length(4, "Pin must be 4 numbers long")
    .matches(/^\d+$/),
});
